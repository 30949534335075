// jshint strict:false, undef:false, unused:false

/*/
	Crema Captcha Init
	Stephen Ginn at Crema Design Studio
	Updated 2020-07-20
/*/

(function () {
	// Data Masking
	document.querySelectorAll("[type=tel]").forEach(input => {
		IMask(input, {mask: '(000) 000-0000'});
	});

	// Crema Captcha 4.3.7
	captcha('[data-captcha]');

	ajaxFormInit();
})();
